import React,{Fragment} from 'react';
import cx from "classnames";
import moment from "moment";
import useStore from "../../../../modules/utils/store";
import { useEffect,useState } from 'react';
import { _API, _U } from '../../../../modules/utils';
import noneListImg from '../../../../resources/login/image/none_list.svg';
export default (props) => { 
  const { eventList,onSetEventListActive,gstShowTerms,onSetGuestInfoList } = props;
  const { siteInfo } = useStore();
  const [fieldItem, setFieldItem] = useState(null);
  const [dynamicFieldItem, setDynamicFieldItem] = useState(null);
  const [dynamicFieldSaveItem, setDynamicFieldSaveItem] = useState(null);
  const [allChecked, setAllChecked] = useState(false);

  const termsCount = [siteInfo?.gstTermsPrivacyYn == 'Y', siteInfo?.gstTermsUseYn == 'Y', siteInfo?.gstTermsOverseaYn == 'Y', siteInfo?.gstTermsMarketYn == 'Y'].filter(Boolean).length;

  const onAllCheck = (e) => {
    const checked = e.target.checked;
    setAllChecked(checked);

    const checkboxes = document.querySelectorAll('.agree_form input[type="checkbox"]');

    checkboxes.forEach((checkbox) => {
      if (checkbox.id !== 'login_agree_all') {
        checkbox.checked = checked;
      }
    });
  };

  const onIndividualCheck = () => {

    const checkboxes = document.querySelectorAll('.agree_form input[type="checkbox"]:not(#login_agree_all)');
    const allCheckedStatus = Array.from(checkboxes).every(checkbox => checkbox.checked);

    setAllChecked(allCheckedStatus);

    document.getElementById('login_agree_all').checked = allCheckedStatus;
  };

  useEffect(() => {
    if (siteInfo?.idx > 0) {
      console.log("2????")
      loadGuestField();
    }
  }, []);

  const loadGuestField = () => {
    const req = {
      path: "/v1/client/findGuestField",
      data: {
        siteIdx : siteInfo.idx,
      },
    };
    _API.post(req).then((res) => {
        const {dynamicFieldItem,fieldItem} = res.data;
        setFieldItem(fieldItem);
        setDynamicFieldItem(dynamicFieldItem);
        console.log("dynamicFieldItem ==> ",dynamicFieldItem)
        console.log("fieldItem ==> ",fieldItem)
    }).catch((e) => {
      console.log(e)  
    });
  };

  const guestSave = (e,i) => {
    let guestInfo = eventList[i];
    if (!guestInfo?.eventIdx) {
      alert("접속할 이벤트 정보를 선택해주세요.");
      return;
    }

    let map = new Map();
    const temp = e.target.parentElement.parentElement.children;
    for (let i = 0; i < temp.length; i++) {
      const element = temp[i];
      if(element.className == "login_input_div"){
        map.set(element.children[0].id,element.children[0].value)
      }  
    }
   
    if (fieldItem?.nameUse == "Y") {
      if (fieldItem.nameRequired === "Y") {
        if(map == null || map.get("guestName") == null || map.get("guestName") == ""){
          alert(fieldItem.nameLabel + `은(는) 필수 항목입니다.`);
          return;
        }
      }
    }
    if (fieldItem?.companyUse == "Y") {
      if (fieldItem.companyRequired === "Y") {
        if(map == null || map.get("guestCompany") == null || map.get("guestCompany") == ""){
          alert(fieldItem.companyLabel + `은(는) 필수 항목입니다.`);
          return;
        }
      }
    }
    if (fieldItem?.deptUse == "Y") {
      if (fieldItem.deptRequired === "Y") {
        if(map == null || map.get("guestDept") == null || map.get("guestDept") == ""){
          alert(fieldItem.deptLabel + `은(는) 필수 항목입니다.`);
          return;
        }
      }
    }
    if (fieldItem?.mobileUse == "Y") {
      if (fieldItem.mobileRequired === "Y") {
        if(map == null || map.get("guestMobile") == null || map.get("guestMobile") == ""){
          alert(fieldItem.mobileLabel + `은(는) 필수 항목입니다.`);
          return;
        }
      }
    }
    if (map.get("guestMobile") && !_U.isMobilePhoneNumber(map.get("guestMobile"))) {
      alert(fieldItem.mobileLabel+" 형식을 확인해주세요.");
      return;
    }
    if (fieldItem?.emailUse == "Y") {
      if (fieldItem.emailRequired === "Y") {
        if(map == null || map.get("guestEmail") == null || map.get("guestEmail") == ""){
          alert(fieldItem.emailLabel + `은(는) 필수 항목입니다.`);
          return;
        }
      }
    }
    if (map.get("guestEmail") && !_U.isEmail(map.get("guestEmail"))) {
      alert(fieldItem.emailLabel+" 형식을 확인해주세요.");
      return;
    }

    if ( dynamicFieldItem && dynamicFieldItem?.length > 0 && fieldItem?.dynamicFieldUse == "Y" ) {
      for (let i = 0; i < dynamicFieldItem.length; i++) {
        const element = dynamicFieldItem[i];
        if (element.useyn === "Y") {
          if(element.required == "Y"){
            if(map == null || map.get(`guestEtc`+element.idx) == null || map.get(`guestEtc`+element.idx) == ""){
              alert(element.label + `은(는) 필수 항목입니다.`);
              return;
            }
          }
        }
      }
    }
   
    let termsPrivacyChk = true;
    let termsUseChk = true;
    let termsOverseaChk = true;
    let termsMarketChk = true;
    const chkedValue = e.target.parentElement.previousElementSibling.children[0].children;
    for (let i = 0; i < chkedValue.length; i++) {
      const temp = chkedValue[i];
      const element = temp.children[0].children[0]
      if(siteInfo?.gstTermsPrivacyYn != 'N' && element.id == "login_agree_box1") {
        termsPrivacyChk = element.checked;
      }
      if(siteInfo?.gstTermsOverseaYn != 'N' && element.id == "login_agree_box2") {
        termsOverseaChk = element.checked;
      }
      if(siteInfo?.gstTermsUseYn != 'N' && element.id == "login_agree_box3") {
        termsUseChk = element.checked;
      }
      if(siteInfo?.gstTermsMarketYn != 'N' && element.id == "login_agree_box4") {
        termsMarketChk = element.checked;
      }
    }
    if(siteInfo?.gstTermsPrivacyEsYn != 'N') {
      if(termsPrivacyChk != true){
        const val = siteInfo?.gstTermsPrivacyLabel;
        alert(`${val}에 동의해 주세요.`);
        return;
      }
    } 
    if(siteInfo?.gstTermsOverseaEsYn != 'N') {
      if(termsOverseaChk != true){
        const val = siteInfo?.gstTermsOverseaLabel;
        alert(`${val}에 동의해 주세요.`);
        return;
      }
    } 
    if(siteInfo?.gstTermsUseEsYn != 'N') {
      if(termsUseChk != true){
        const val = siteInfo?.gstTermsUseLabel;
        alert(`${val}에 동의해 주세요.`);
        return;
      }
    } 
    if(siteInfo?.gstTermsMarketEsYn != 'N') {
      if(termsMarketChk != true){
        const val = siteInfo?.gstTermsMarketLabel;
        alert(`${val}에 동의해 주세요.`);
        return;
      }
    } 

    let dynamicList = [];
    const mapIter = map.keys();
    for (let i = 0; i < map.size; i++) {
      let a = [];
      let key = mapIter.next().value;
      const element = map[i];
      if(key.includes("guestEtc")){
        let num = key.lastIndexOf("c") + 1;
        key = key.substr(num, key.length)
        a.push(key)
        a.push(map.get(`guestEtc`+key))
        dynamicList.push(a);
      }
    }
   
    const req = {
      path: "/v1/client/guest/save",
      data: {
        targetType: "GUEST",
        name: map.get("guestName"),
        companyName: map.get("guestCompany"),
        deptName: map.get("guestDept"),
        mobile: map.get("guestMobile"),
        email: map.get("guestEmail"),
        dynamicList:dynamicList,
        // 사이트정보
        siteIdx: siteInfo.idx,
        eventIdx: guestInfo?.eventIdx,
        passHead: "",
        termsPrivateAgree: termsPrivacyChk,
        termsUseAgree: termsUseChk,
        inviteStatus: "Y",
        fieldList: guestInfo?.preregFieldList,
        userAgent: _U.getUserAgent(),
      },
    };
    _API.post(req).then((res) => {
      const { data } = res;
      if (data) {
        window.location.href = `/#/event/${guestInfo?.eventIdx}/register?passCode=${data?.passCode}&type=guest`;
        window.location.reload();
      } else {
        alert("등록중 오류가 발생하였습니다.");
      }
    });
  };
  

  return (
    <div className="login_box gst_box">
      {eventList?.filter((evt) => evt?.todayYn == "Y" || evt?.onAirYn == "Y").length == 0 ? (
        <div className="none_event">
          <img src={noneListImg} alt=""/>
          <span>현재 진행중인 심포지엄이 없습니다.</span>
        </div>
      ) : ""}
      {eventList?.map((item, i) => (
        <Fragment key={i}>
        {(item?.todayYn === "Y" || item?.onAirYn === "Y") && (
            <div
              className={cx({
                login_session: true,
                gst_session: true,
                active: item.activeYn,
              })}
            >
              <div
                onClick={(e) =>
                  onSetEventListActive(i, item.eventIdx)
                }
                className="login_session_inner"
              >
                <div className="gst_session_hd">
                  <h4 className="login_session_h4">
                    <a>{item.eventName}</a>
                  </h4>
                  <a
                    className={cx({
                      morebtn: true,
                      active: item.activeYn,
                    })}
                  >
                    <img
                      src="https://cdn.onoffevent.com/common/arrow_select.svg"
                      alt="더보기"
                    />
                  </a>
                </div>
                <p className="login_session_p">
                  {moment(item?.startDate).format(
                    "YYYY.MM.DD(ddd) HH:mm"
                  )}{" "}
                  ~{" "}
                  {moment(item?.endDate).format(`
                    ${moment(item?.startDate).format('YYYY.MM.DD') == moment(item?.endDate).format('YYYY.MM.DD') ? 'HH:mm' : 'YYYY.MM.DD(ddd) HH:mm'}`
                  )}
                </p>
              </div>
              {item.activeYn && (
                <div
                  className={cx({
                    gst_session_sub: true,
                    form_wrap: true,
                    on: item.activeYn,
                  })}
                >
                <BasicFieldsForm 
                  label = {fieldItem?.nameLabel}
                  useYn = {fieldItem?.nameUse}
                  required = {fieldItem?.nameRequired}
                  id="guestName"
                />
                <BasicFieldsForm 
                  label = {fieldItem?.companyLabel}
                  useYn = {fieldItem?.companyUse}
                  required = {fieldItem?.companyRequired}
                  id="guestCompany"
                />
                <BasicFieldsForm 
                  label = {fieldItem?.deptLabel}
                  useYn = {fieldItem?.deptUse}
                  required = {fieldItem?.deptRequired}
                  id="guestDept"
                />
                <BasicFieldsForm 
                  label = {fieldItem?.mobileLabel}
                  useYn = {fieldItem?.mobileUse}
                  required = {fieldItem?.mobileRequired}
                  id="guestMobile"
                />
                <BasicFieldsForm 
                  label = {fieldItem?.emailLabel}
                  useYn = {fieldItem?.emailUse}
                  required = {fieldItem?.emailRequired}
                  id="guestEmail"
                />

                {fieldItem?.dynamicFieldUse === "Y" && (
                  <Fragment>
                    {dynamicFieldItem?.map(
                      (item, j) => (
                        <Fragment key={j}>
                          {item?.useyn === "Y" && (
                            <BasicFieldsForm 
                              label = {item?.label}
                              useYn = {item?.useyn}
                              required = {item?.required}
                              id={`guestEtc`+item?.idx}
                            />
                          )}
                        </Fragment>
                      )
                    )}
                  </Fragment>
                )}
                {(siteInfo?.gstTermsPrivacyYn === 'Y' 
                  || siteInfo?.gstTermsUseYn === 'Y'
                  || siteInfo?.gstTermsOverseaYn === 'Y' 
                  || siteInfo?.gstTermsMarketYn === 'Y') &&(
                    <div className="agree_form">
                      <ul>
                        {termsCount >= 2 && (
                          <li>
                            <p>
                              <input
                                type="checkbox"
                                id="login_agree_all"
                              />
                              <label
                                id="chk_label"
                                htmlFor="login_agree_all"
                              >
                                <span></span>
                                모두 동의합니다.
                              </label>
                            </p>
                          </li>
                        )}
                      {siteInfo?.gstTermsPrivacyYn === 'Y' && (
                        <li>
                          <p>
                            <input
                              type="checkbox"
                              id="login_agree_box1"
                              onChange={onIndividualCheck}
                            />
                            <label
                              id="chk_label"
                              htmlFor="login_agree_box1"
                            >
                              <span></span>
                              {siteInfo?.gstTermsPrivacyLabel} {siteInfo?.gstTermsPrivacyEsYn == 'Y' ? '(필수)' : '(선택)'}
                              </label>
                          </p>
                          <a style={{ cursor: "pointer",whiteSpace: "nowrap" }}
                            onClick={gstShowTerms.bind(
                              this,
                              "termsPrivateAgree"
                            )}
                          >
                            내용보기
                          </a>
                        </li>
                        )}
                        {siteInfo?.gstTermsOverseaYn === 'Y' && (
                        <li>
                          <p>
                            <input
                              type="checkbox"
                              id="login_agree_box2"
                              onChange={onIndividualCheck}
                            />
                            <label
                              id="chk_label"
                              htmlFor="login_agree_box2"
                            >
                              <span></span>
                              {siteInfo?.gstTermsOverseaLabel} {siteInfo?.gstTermsOverseaEsYn == 'Y' ? '(필수)' : '(선택)'}
                            </label>
                          </p>
                          <a style={{ cursor: "pointer",whiteSpace: "nowrap" }}
                            onClick={gstShowTerms.bind(
                              this,
                              "termsOverseaAgree"
                            )}
                          >
                            내용보기
                          </a>
                        </li>
                        )}
                        {siteInfo?.gstTermsUseYn === 'Y' && (
                        <li>
                          <p>
                            <input
                              type="checkbox"
                              id="login_agree_box3"
                              onChange={onIndividualCheck}
                            />
                            <label
                              id="chk_label"
                              htmlFor="login_agree_box3"
                            >
                              <span></span>
                              {siteInfo?.gstTermsUseLabel} {siteInfo?.gstTermsUseEsYn == 'Y' ? '(필수)' : '(선택)'}
                            </label>
                          </p>
                          <a style={{ cursor: "pointer",whiteSpace: "nowrap" }}
                            onClick={gstShowTerms.bind(
                              this,
                              "termsUseAgree"
                            )}
                          >
                            내용보기
                          </a>
                        </li>
                        )}
                        {siteInfo?.gstTermsMarketYn === 'Y' && (
                        <li>
                          <p>
                            <input
                              type="checkbox"
                              id="login_agree_box4"
                              onChange={onIndividualCheck}
                            />
                            <label
                              id="chk_label"
                              htmlFor="login_agree_box4"
                            >
                              <span></span>
                              {siteInfo?.gstTermsMarketLabel} {siteInfo?.gstTermsMarketEsYn == 'Y' ? '(필수)' : '(선택)'}
                            </label>
                          </p>
                          <a style={{ cursor: "pointer",whiteSpace: "nowrap" }}
                            onClick={gstShowTerms.bind(
                              this,
                              "termsMarketAgree"
                            )}
                          >
                            내용보기
                          </a>
                        </li>
                        )}
                      </ul>
                    </div>
                  )}
                  <div className="login_enter">
                    <span onClick={(e) => guestSave(e,i)}>
                      {siteInfo != null ? (siteInfo.gstLoginBtnLabel != null && siteInfo.gstLoginBtnLabel != "" ? siteInfo.gstLoginBtnLabel : "입장하기") : "입장하기"}
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
      </Fragment>
      ))}
    </div>
  )
}

const BasicFieldsForm = (props) => {
  const {label, useYn, required, id} = props;

  return (
    <>
      {useYn == 'Y' && (
        <div className="login_input_div">
        <input
          className="login_input"
          type="text"
          placeholder={label + (required === "Y" ? ` (필수)` : ` (선택)`)}
          defaultValue=""
          id={id}
        />
      </div>
      )}
    </>
  )
}
