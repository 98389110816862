/* eslint-disable */
import React, {
  useContext,
  useEffect,
  useState,
  Fragment,
  useRef,
} from "react";
import styled from "styled-components";
import cx from "classnames";
import swal from "sweetalert";
import moment from "moment";

import { AppContext } from "../../../../components/AppProvider";
import { _API, _CK, _U } from "../../../../modules/utils";
import { DynamicStyles } from "../Resources/GlobalStyle";

import ChromeLogo from "../../../../resources/login/image/chrome.png";
import SafariLogo from "../../../../resources/login/image/safari.png";
import EdgeLogo from "../../../../resources/login/image/edge.png";

import { cardClasses } from "@mui/material";

import LoginPasscodeView from "./Login.Passcode.View"
import LoginMemberView from "./Login.Member.View"
import LoginGuestView from "./Login.Guest.View"
import useStore from "../../../../modules/utils/store";
import noneCampaignImg from '../../../../resources/login/image/none_campaign.svg';

export default (props) => {
  const { auth, saveAuth, saveIam } = useContext(AppContext);
  const { history, match, eventIdx } = props;
  const { siteInfo } = useStore();
  const [info, setInfo] = useState({});
  const [loginTabArray, setLoginTabArray] = useState([]);
  const [tab, setTab] = useState();
  const loginEventIdx = eventIdx || match?.params.eventIdx || null;
  const [eventList, setEventList] = useState([]);
  const [idCheck, setIdCheck] = useState(_CK.get("idCheck"));
  const [loginType, setLoginType] = useState("login");
  const [member, setMember] = useState({});
  const [termsInfo, setTermsInfo] = useState(null);
  const [findMember, setFindMember] = useState({});
  const [scrollY, setScrollY] = useState(0);
  const [passcodeNext, setPasscodeNext] = useState(null);
  const [preregEventList, setPreregEventList] = useState([]);
  const documentRef = useRef(document);
  const [accessKey, setAccessKey] = useState(null);
  const [iam, setIam] = useState(null);
  const [guestInfoList, setGuestInfoList] = useState(null);
  const [passCodeOpt, setPassCodeOpt] = useState([]);
  const [passCodePrivacyOpen, setPassCodePrivacyOpen] = useState(false);
  const [passCodeUseOpen, setPassCodeUseOpen] = useState(false);
  const [passCodeOverseaOpen, setPassCodeOverseaOpen] = useState(false);
  const [passCodeTopOpen, setPassCodeTopOpen] = useState(false);
  const [passCodePrivacyAgree, setPassCodePrivacyAgree] = useState(false);
  const [passCodeUseAgree, setPassCodeUseAgree] = useState(false);
  const [passCodeOverseaAgree, setPassCodeOverseaAgree] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [openModal, setOpenModal] = useState(null); 
 
  const handleScroll = () => {
    const { pageYOffset } = window;
    setScrollY(pageYOffset);
  };

  useEffect(() => {
    const newLoginTabArray = [];
    if (siteInfo?.loginMemYn === "Y") {
      newLoginTabArray.push({ order: siteInfo?.loginMemOrd, name: "Member" });
    }
    if (siteInfo?.loginPassYn === "Y") {
      newLoginTabArray.push({ order: siteInfo?.loginPassOrd, name: "Passcode" });
    }
    if (siteInfo?.loginGstYn === "Y") {
      newLoginTabArray.push({ order: siteInfo?.loginGstOrd, name: "Guest" });
    }
    newLoginTabArray.sort(function (a, b) {
      return b.order - a.order;
    });
    setLoginTabArray(newLoginTabArray);
    if (newLoginTabArray && newLoginTabArray.length > 0){
      setTab(newLoginTabArray[0].name);
    }
    loadEventList();

    if (siteInfo?.idx > 0) {
      loadPassCodeOption();
    }
   
    if (_CK.get("idCheck") === "Y" && _CK.get("email")) {
      setInfo((o) => {
        return { ...o, ["email"]: _CK.get("email") };
      });
    }
  }, []);

  const loadPassCodeOption = () => {
    const req = {
      path: "/v1/client/passcodeOpt",
      data: {
        idx : siteInfo.idx,
      },
    };
    _API.post(req).then((res) => {
        const { data } = res;
        setPassCodeOpt(data.data)
    }).catch((e) => {
      console.log(e)  
    });
  };

  const onSetInfo = (key, value) => {
    if (key === "RESET") {
      setInfo((o) => {
        return {};
      });
    } else {
      setInfo((o) => {
        return { ...o, [key]: value };
      });
    }
  };

  const onSetEventListActive = (i) => {
    setEventList((o) => {
      const nb = [...o];
      nb.map((key, j) => {
        if (i == j) {
          nb[j].activeYn = nb[i].activeYn === true ? false : true;
        } else {
          nb[j].activeYn = false;
        }
      });
      return nb;
    });
  };

  const onSetGuestInfoList = (key, i, j, value) => {
    setEventList((o) => {
      const nb = [...o];
      nb.map((key2, k) => {
        if (i == k) {
          if (j == null) {
            let newVal = value;

            if (key === "termsPrivateAgree") {
              newVal = nb[i]?.termsPrivateAgree === "Y" ? "N" : "Y";
            } else if (key === "termsUseAgree") {
              newVal = nb[i]?.termsUseAgree === "Y" ? "N" : "Y";
            }
            nb[i] = { ...nb[i], [key]: newVal };
          } else {
            nb[i].preregFieldList[j] = {
              ...nb[i].preregFieldList[j],
              ["value"]: value,
            };
          }
        }
      });
      return nb;
    });
  };

  const loginTabChange = (name, e) => {
    if (e) e.preventDefault();
    setTab(name);
    setInfo({});
    setFindMember({});
  };

  const loadEventList = () => {
    if (!siteInfo.idx) {
      alert("This is not the right approach.");
      return;
    }
    const req = {
      path: "/v1/client/site/event/list",
      data: {
        siteIdx: siteInfo.idx,
        eventProgressStatus: "TODAY",
        eventStatus: "Y",
      },
    };
    
    _API.post(req).then((res) => {
      const { data } = res.data;
      if(data) {
        if (data.length == 1) {
          for (let i in data) {
            if(data[i].upcomingYn == "Y" || data[i].onAirYn == "Y") {
              data[i].activeYn = true
            } else {
              data[i].activeYn = false
            }
          }
        } else {
          for(let i in data) {
            data[i].activeYn = false
          }
        }
        setEventList(data);
      }
    });
  };
  
  const clickedIdCheck = () => {
    const idCheck = _CK.get("idCheck");
    if (idCheck === "Y") {
      _CK.set("idCheck", "N");
      setIdCheck("N");
    } else {
      _CK.set("idCheck", "Y");
      setIdCheck("Y");
    }
  };

  const onLoginClick = (e) => {
    if(e) e.preventDefault();
    if (tab === "Member"){
      if(document.getElementById("member_id").value == null || document.getElementById("member_id").value == ""){
        swal("Please enter the E-mail.");
        return;
      }else{
        info["email"] = document.getElementById("member_id").value;
      }

      if(document.getElementById("member_password").value == null || document.getElementById("member_password").value == ""){
        swal("Please enter the Password.");
        return;
      }else{
        info["password"] = document.getElementById("member_password").value;
      }
    }
    if (tab === "Passcode"){
      if(document.getElementById("psc_password").value == null || document.getElementById("psc_password").value == ""){
        swal("Please enter your passcode.");
        return;
      }else{
        info["passCode"] = document.getElementById("psc_password").value;
      }      
    }

    if (tab === "Passcode"){
      if(passCodeOpt.length > 0){

        let array = passCodeOpt[0];

        const param = {
          path: "/v1/client/agreeStatus",
          data: {
            siteIdx: siteInfo.idx,
            passCode: info.passCode,
          },
        };

        _API.post(param).then((res) => {
          if(res.data.status == 0){
            if((passCodeUseOpen == false &&  array.termsUseFormUse == "Y") && (passCodePrivacyOpen == false && array.termsPrivacyFormUse == "Y") && (passCodeOverseaOpen == false && array.termsOverseaUse == "Y")){
              setPassCodeUseOpen(true)
              setPassCodePrivacyOpen(true)
              setPassCodeOverseaOpen(true)
              return;
            }
            if((passCodeUseOpen == false &&  array.termsUseFormUse == "Y") && (passCodePrivacyOpen == false && array.termsPrivacyFormUse == "Y")){
              setPassCodeUseOpen(true)
              setPassCodePrivacyOpen(true)
              return;
            }
            if((passCodeUseOpen == false &&  array.termsUseFormUse == "Y") && (passCodeOverseaOpen == false && array.termsOverseaUse == "Y")){
              setPassCodeUseOpen(true)
              setPassCodeOverseaOpen(true)
              return;
            }
            if((passCodePrivacyOpen == false && array.termsPrivacyFormUse == "Y") && (passCodeOverseaOpen == false && array.termsOverseaUse == "Y")){
              setPassCodePrivacyOpen(true)
              setPassCodeOverseaOpen(true)
              return;
            }

            if(passCodePrivacyOpen == false && array.termsPrivacyFormUse == "Y"){
              setPassCodePrivacyOpen(true)
              return;
            }
            if(passCodeUseOpen == false &&  array.termsUseFormUse == "Y"){
              setPassCodeUseOpen(true)
              return;
            }
            if(passCodeOverseaOpen == false &&  array.termsOverseaUse == "Y"){
              setPassCodeOverseaOpen(true)
              return;
            }

            if(array.termsPrivacyFormEsUse == "Y" && passCodePrivacyAgree != true){
              const val = array.termsPrivacyFormLabel;
              alert(`Please agree to the consent for ${val}.`);
              return;
            }
            if(array.termsOverseaEsUse == "Y" && passCodeOverseaAgree != true){
              const val = array.termsOverseaLabel;
              alert(`Please agree to the consent for ${val}.`);
              return;
            }
            if(array.termsUseFormEsUse == "Y" && passCodeUseAgree != true){
              const val = array.termsUseFormLabel;
              alert(`Please agree to the consent for ${val}.`);
              return;
            }
          
            goLogin(res.data.status) 
          }else{
            goLogin(1) 
          }
        })
      }else{
        goLogin(1) 
      }
    }else{
      goLogin(1) 
    }
  };

  const goLogin = (firstLogin) => {
    const req = {
      path: "/v1/client/login",
      data: {
        email: info.email,
        password: info.password,
        passCode: info.passCode,
        siteIdx: siteInfo.idx,
        eventIdx: '',
        tab : tab === "Passcode" ? "PASSCODE" : (tab === "Member" ? "MEMBER" : "GUEST"),
        userAgent: _U.getUserAgent(),
      },
    };
    _API.post(req).then((res) => {
      const { data } = res;
      if (res.status !== "SUCCESS") {
        swal("Do not have access rights.");
        return;
      }
      if(tab == 'Member' && eventList?.length <= 0){
        alert('There are no events scheduled.');
        return;
      }
      if (tab === "Member" && _CK.get("idCheck") === "Y") {
        _CK.set("email", info.email);
      }
      if (tab === "Passcode" && data.accessKey) {
        _CK.setConsoleCountView(0);
        setIam(data.data);
        setAccessKey(data.accessKey);
        setPreregEventList(data.data.preregEventList);
        setPasscodeNext(true);
        
        if(firstLogin == 0){
          const param = {
            path: "/v1/client/termsAgree",
            data: {
              siteIdx: siteInfo.idx,
              passCode : data.data.passCode,
              passCodeUseAgree : passCodeUseAgree,
              passCodePrivacyAgree : passCodePrivacyAgree,
              passCodeOverseaAgree : passCodeOverseaAgree
            },
          };
          _API.post(param).then((res) => {
            console.log(res)
          })
        }
      } else {
        _CK.setAuth(data.accessKey);
        _CK.setLogKey(data?.data?.logKey);
        _CK.setConsoleCountView(0);
        saveIam(data.data);
        saveAuth(data.accessKey);
      }
    })
    .catch((e) => {
      // swal("접근 권한이 없습니다.");
    });
  }
  const goEventSessionList = (event, e) => {
    if (e) e.preventDefault();

    if (event?.sessionPubCnt <= 0) {
      alert("There is no scheduled session information.");
      return;
    }
  
    let newIam = iam;
    newIam.eventIdx = event.idx;
    setIam(newIam);
    const req = {
      path: "/v1/client/refresh/accessKey",
      data:{iam:newIam}
    };
    _API.post(req).then((res) => {
      const { accessKey } = res.data;
      if (accessKey) {
        _CK.setAuth(accessKey);

        setTimeout(()=>{
          window.location.href = `/#/event/${event.idx}/register?passCode=${info?.passCode}`;
          window.location.reload();
      },300)
      }
    });
    
  };
  
  const goMypage = e => {
    e?.preventDefault();
    let newIam = iam;
    newIam.eventIdx = event.idx;
    setIam(newIam);
    const req = {
      path: "/v1/client/refresh/accessKey",
      data:{iam:newIam}
    };
    _API.post(req).then((res) => {
      const { accessKey } = res.data;
      if (accessKey) {
        _CK.setAuth(accessKey);

        setTimeout(()=>{
          window.location.href = '/#/invitation';
          window.location.reload();
      },300)
      }
    });
  };

  const passCodeShowTerms = (type, e) => {
    if (e) e.preventDefault();
   
    $("body").css("overflow", "hidden");
    
    if (type === "termsUseAgree") {
      setTermsInfo({
         title: passCodeOpt[0]?.termsUseFormLabel,
         content: passCodeOpt[0]?.termsUseForm,
       });
     } else if (type === "termsPrivateAgree") {
      setTermsInfo({
         title: passCodeOpt[0]?.termsPrivacyFormLabel,
         content: passCodeOpt[0]?.termsPrivacyForm,
       });
     }else if (type === "termsOverseaAgree") {
      setTermsInfo({
         title: passCodeOpt[0]?.termsOverseaLabel,
         content: passCodeOpt[0]?.termsOversea,
       });
     }
     else {
      setTermsInfo(null);
     }

  }

  const gstShowTerms = (type, e) => {
    if (e) e.preventDefault();

    $("body").css("overflow", "hidden");

    if (type === "termsUseAgree") {
      setTermsInfo({
        title: siteInfo?.gstTermsUseLabel,
        content: siteInfo?.gstTermsUseForm,
       });
     } else if (type === "termsPrivateAgree") {
       setTermsInfo({
        title: siteInfo?.gstTermsPrivacyLabel,
        content: siteInfo?.gstTermsPrivacyForm,
       });
     }else if (type === "termsOverseaAgree") {
      setTermsInfo({
        title: siteInfo?.gstTermsOverseaLabel,
        content: siteInfo?.gstTermsOverseaForm,
      });
    }
     else if (type === "termsMarketAgree") {
       setTermsInfo({
        title: siteInfo?.gstTermsMarketLabel,
        content: siteInfo?.gstTermsMarketForm,
       });
     }
     else {
       setTermsInfo(null);
     }

  }

  const showTerms = (type, e) => {
    if (e) e.preventDefault();
    $("body").css("overflow", "hidden");

    if (type === "termsUseAgree") {
     setTermsInfo({
        title: "Site Terms of Use",
        content: siteInfo?.memTermsUseForm,
      });
    } else if (type === "termsPrivateAgree") {
      setTermsInfo({
        title: "Personal information collection and use agreement",
        content: siteInfo?.memTermsPrivacyForm,
      });
    }
    else if (type === "termsMarket") {
      setTermsInfo({
        title: "Consent to receive marketing information",
        content: siteInfo?.memTermsMarketForm,
      });
    }
     
    else {
      setTermsInfo(null);
    }
  };

  const linkRel = () => { 
    window.open("https://nedrug.mfds.go.kr/pbp/CCBBB01/getItemDetail?itemSeq=201601746")
  }

  useEffect(() => {
    $("#openModal").click(function (e) {
      e.preventDefault();
      $("body").css("overflow", "hidden");
      setOpenModal(true);
    })
  }, []);
  const BrowserClose = () => {
    $(".modal_container").hide();
  }
  const modalClose = () => {
    setTermsInfo(null);
    setOpenModal(false);
    $("body").css("overflow", "auto");
  };
 
  useEffect(() => {
    eventList, documentRef.current.addEventListener("scroll", handleScroll);
  });
 
  return (
    <Fragment>
      <div style={{ height: "100%" }}>
        <section id="login_container">
          <div
            className="bg_img"
            style={{
              background: `${siteInfo?.siteMainImgUrl ? `url(${siteInfo?.siteMainImgUrl})` : (`url("https://cdn.onoffevent.com/bg/Site%20bg.png")` )}`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          >
            <div className="bg_img_hd">
              {siteInfo?.nameLoginPageShow == 'Y' ? (
                  <h1 style={{ color: siteInfo.nameColor }}>{siteInfo?.title}</h1>
              )
                : ""
              }
            </div>
          </div>
          <div className="login_inform">
            {(siteInfo?.logoUseYn == "Y" && siteInfo?.logoImgUrl) &&
              <div className="brd_logo" style={{ textAlign: "center" }}>
                { siteInfo?.logoImgUrl == null || siteInfo?.logoImgUrl == "" ?
                  "" :
                  <img src={siteInfo.logoImgUrl} alt={siteInfo?.name} />
                }
              </div>
            }
            <div className="login_inform_wrap">
              <ul className="login_inform_list">
                {loginTabArray.length > 1 ? (
                  <Fragment>
                    {loginTabArray?.map((item, i) => (
                      <li
                        key={i}
                        className={cx({ active: tab === item.name })}
                        onClick={loginTabChange.bind(this, item.name)}
                        style={{
                          width:
                            "calc( 100% / " + loginTabArray.length + " )",
                        }}
                      >
                        <span className={cx({ on: tab === item.name })}>
                          {item.name}
                        </span>
                      </li>
                    ))}
                  </Fragment>
                ) : (
                  <Fragment>
                    {loginTabArray?.map((item, i) => (
                      <div className="login_inform_list_div" key={i}>
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </Fragment>
                )}
              </ul>
              <form onSubmit={(e) => {e.preventDefault();}}>
                {tab === "Member" && (
                  <LoginMemberView
                    info={info}
                    idCheck={idCheck}
                    onLoginClick={onLoginClick}
                    clickedIdCheck={clickedIdCheck}
                    onSetInfo={onSetInfo}
                  />
                )}
                {tab === "Passcode" && (
                  <LoginPasscodeView
                    info={info}
                    passCodeOpt={passCodeOpt}
                    passcodeNext={passcodeNext}
                    isPasswordVisible={isPasswordVisible}
                    passCodePrivacyOpen={passCodePrivacyOpen}
                    passCodeUseOpen={passCodeUseOpen}
                    passCodeOverseaOpen={passCodeOverseaOpen}
                    onLoginClick={onLoginClick}
                    onSetInfo={onSetInfo}
                    preregEventList={preregEventList}
                    setIsPasswordVisible={setIsPasswordVisible}
                    goEventSessionList={goEventSessionList}
                    goMypage={goMypage}
                    passCodeShowTerms={passCodeShowTerms}
                    setPassCodePrivacyAgree={setPassCodePrivacyAgree}
                    setPassCodeUseAgree={setPassCodeUseAgree}
                    setPassCodeOverseaAgree={setPassCodeOverseaAgree}
                  />
                )}
                {tab === "Guest" && (
                  <LoginGuestView
                    siteInfo={siteInfo}
                    eventList={eventList}
                    onSetEventListActive={onSetEventListActive}
                    gstShowTerms={gstShowTerms}
                    onSetGuestInfoList={onSetGuestInfoList}
                  />
                )}
              </form>
              <div className="cs_container">
                <h4>{siteInfo?.cs}</h4>
                <p dangerouslySetInnerHTML={{ __html: siteInfo?.csDescription }}/>
              </div>
              <div className="bg_img_ft">
                <pre
                  dangerouslySetInnerHTML={{
                    __html: siteInfo?.footer?.replace(/\n/g, ""),
                  }}
                />
                {tab == "Member" && siteInfo?.memberFooterCheck === "Y" ? (
                  <div className="ft_info2" style={{ marginTop: "30px" }}>
                    <a onClick={showTerms.bind(this, "termsPrivateAgree")}>Privacy policy.</a>
                    <a onClick={showTerms.bind(this, "termsUseAgree")}>Terms of Use</a>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </section>
        {termsInfo && (
          <Fragment>
            <div className="ft_modal_container">
              <div className="ft_modal_header">
                <div>
                  <h2>{termsInfo?.title}</h2>
                  <button
                    className="close_btn"
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      modalClose();
                    }}
                  >
                    <img src="https://cdn.onoffevent.com/common/icon_close.svg" />
                  </button>
                </div>
              </div>
              <div className="ft_modal_content">
                <div
                  dangerouslySetInnerHTML={{ __html: termsInfo?.content }}
                />
              </div>
            </div>
            <div className="modal_bg on"></div>
          </Fragment>
        )}
        {openModal && (
          <div className="modal_container">
            <button className="modal_close" onClick={modalClose}><img src="https://cdn.onoffevent.com/common/icon_close.svg" alt="Close"/></button>
            <div className="modal_mid">
              <h4 style={{fontSize: "19px", wordBreak: "keep-all"}}>
                You are attempting to navigate to a different page on the GSK Korea website.<br/>
                By clicking this link, you will be redirected to a different website or web channel.
              </h4>
              <span>
                The page you are about to access may be a related site of GSK Korea, a global GSK site, or an affiliate site where Korean regulations may not apply.
                If you are redirected to a third-party site that is not managed or owned by GSK, GSK is not responsible for the content provided on that site.
              </span>
            </div>
            <div className="btnBox">
              <button className="modalBtn cancelBtn" onClick={modalClose}>Cancle</button>
              <button className="modalBtn confirmBtn" onClick={linkRel}>Continue</button>
            </div>
          </div>
        )}
        {_U.getUserAgent().browserName != "Chrome"
          && _U.getUserAgent().browserName != "Safari"
          && _U.getUserAgent().browserName != "Mobile Safari"
          && _U.getUserAgent().browserName != "Edge" ? (
            <div className="modal_container">
              <button className="modal_close" onClick={BrowserClose}><img src="https://cdn.onoffevent.com/common/icon_close.svg" alt="Close" /></button>
              <div className="modal_mid">
                <h4>
                  You may have difficulty using the service using the browser you are currently using.<br/>
                  To use the service smoothly, please connect via Chrome/Safari/Edge.
                </h4>
                <span>
                  To get the best possible experience using our website,
                  It is recommended that you use one of the browsers listed below.
                </span>
                <ul>
                  <li>
                    <a href="https://www.google.com/chrome/" target="_blank">
                      <img src={ChromeLogo} alt="chroem logo"/>
                      <span>Chrome</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://support.apple.com/ko_KR/downloads/safari" target="_blank">
                      <img src={SafariLogo} alt="safari logo"/>
                      <span>Safari</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.microsoft.com/ko-kr/edge/" target="_blank">
                      <img src={EdgeLogo} alt="edge logo"/>
                      <span>Edge</span>
                    </a>
                  </li>
                </ul>
                <span style={{textAlign:"center"}}>
                  Click on the browser icon to go to the download screen.<br/>
                  Please click icons to download.
                </span>
              </div>
              <div className="btnBox">
                <button className="modalBtn cancelBtn" onClick={BrowserClose}>Close</button>
              </div>
            </div>
        ):null}
      </div>
      <DynamicStyles />
    </Fragment>
  );
};
