/* eslint-disable */
import React, {
  useContext,
  useEffect,
  useState,
  Fragment,
  useRef,
} from "react";
import styled from "styled-components";
import cx from "classnames";
import swal from "sweetalert";
import moment from "moment";

import { AppContext } from "../../../../components/AppProvider";
import { _API, _CK, _U } from "../../../../modules/utils";
import { DynamicStyles } from "../Resources/GlobalStyle";

import ChromeLogo from "../../../../resources/login/image/chrome.png";
import SafariLogo from "../../../../resources/login/image/safari.png";
import EdgeLogo from "../../../../resources/login/image/edge.png";
import liveImg from "../../../../resources/login/image/Live Image.png";
import { cardClasses } from "@mui/material";
import LoginPasscodeView from "./Login.Passcode.View"
import LoginMemberView from "./Login.Member.View"
import LoginGuestView from "./Login.Guest.View"
import useStore from "../../../../modules/utils/store";
import noneCampaignImg from '../../../../resources/login/image/none_campaign.svg';

export default (props) => {
  const { auth, saveAuth, saveIam } = useContext(AppContext);
  const { history, match, eventIdx } = props;
  const { siteInfo } = useStore();
  const [info, setInfo] = useState({});
  const [loginTabArray, setLoginTabArray] = useState([]);
  const [tab, setTab] = useState();
  const loginEventIdx = eventIdx || match?.params.eventIdx || null;
  const [eventList, setEventList] = useState([]);
  const [idCheck, setIdCheck] = useState(_CK.get("idCheck"));
  const [loginType, setLoginType] = useState("login");
  const [member, setMember] = useState({});
  const [termsInfo, setTermsInfo] = useState(null);
  const [findMember, setFindMember] = useState({});
  const [scrollY, setScrollY] = useState(0);
  const [passcodeNext, setPasscodeNext] = useState(null);
  const [preregEventList, setPreregEventList] = useState([]);
  const documentRef = useRef(document);
  const [accessKey, setAccessKey] = useState(null);
  const [iam, setIam] = useState(null);
  const [guestInfoList, setGuestInfoList] = useState(null);
  const [passCodeOpt, setPassCodeOpt] = useState([]);
  const [passCodePrivacyOpen, setPassCodePrivacyOpen] = useState(false);
  const [passCodeUseOpen, setPassCodeUseOpen] = useState(false);
  const [passCodeOverseaOpen, setPassCodeOverseaOpen] = useState(false);
  const [passCodeTopOpen, setPassCodeTopOpen] = useState(false);
  const [passCodePrivacyAgree, setPassCodePrivacyAgree] = useState(false);
  const [passCodeUseAgree, setPassCodeUseAgree] = useState(false);
  const [passCodeOverseaAgree, setPassCodeOverseaAgree] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [openModal, setOpenModal] = useState(null); 
  const [firstLoginStatus, setFirstLoginStatus] = useState(1);
  const [campaignList, setCampaignList] = useState([]);


  console.log("siteInfo ===> ",siteInfo)

  const handleScroll = () => {
    const { pageYOffset } = window;
    setScrollY(pageYOffset);
  };

  useEffect(() => {
    const newLoginTabArray = [];
    if (siteInfo?.loginMemYn === "Y") {
      newLoginTabArray.push({ order: siteInfo?.loginMemOrd, name: "회원" });
    }
    if (siteInfo?.loginPassYn === "Y") {
      newLoginTabArray.push({ order: siteInfo?.loginPassOrd, name: "패스코드" });
    }
    if (siteInfo?.loginGstYn === "Y") {
      newLoginTabArray.push({ order: siteInfo?.loginGstOrd, name: "게스트" });
    }
    newLoginTabArray.sort(function (a, b) {
      return b.order - a.order;
    });
    setLoginTabArray(newLoginTabArray);
    if (newLoginTabArray && newLoginTabArray.length > 0){
      setTab(newLoginTabArray[0].name);
    }
    loadEventList();

    if (siteInfo?.idx > 0) {
      loadPassCodeOption();
      loadCampaignList();
    }
   
    if (_CK.get("idCheck") === "Y" && _CK.get("email")) {
      setInfo((o) => {
        return { ...o, ["email"]: _CK.get("email") };
      });
    }
  }, []);

  const loadCampaignList = async () => {

    const req = {
      path: "/v1/campaign/channel/list",
      data: {
        siteIdx : siteInfo.idx,
      },
    };

    await _API.post(req).then((res) => {
        const { data } = res.data;
        console.log('data =', data);
        setCampaignList(data)
    }).catch((e) => {
      console.log(e)  
    });

  }

  const loadPassCodeOption = () => {
    const req = {
      path: "/v1/client/passcodeOpt",
      data: {
        idx : siteInfo.idx,
      },
    };
    _API.post(req).then((res) => {
        const { data } = res;
        setPassCodeOpt(data.data)
    }).catch((e) => {
      console.log(e)  
    });
  };

  const onSetInfo = (key, value) => {
    if (key === "RESET") {
      setInfo((o) => {
        return {};
      });
    } else {
      setInfo((o) => {
        return { ...o, [key]: value };
      });
    }
  };

  const onSetEventListActive = (i) => {
    setEventList((o) => {
      const nb = [...o];
      nb.map((key, j) => {
        if (i == j) {
          nb[j].activeYn = nb[i].activeYn === true ? false : true;
        } else {
          nb[j].activeYn = false;
        }
      });
      return nb;
    });
  };

  const onSetGuestInfoList = (key, i, j, value) => {
    setEventList((o) => {
      const nb = [...o];
      nb.map((key2, k) => {
        if (i == k) {
          if (j == null) {
            let newVal = value;

            if (key === "termsPrivateAgree") {
              newVal = nb[i]?.termsPrivateAgree === "Y" ? "N" : "Y";
            } else if (key === "termsUseAgree") {
              newVal = nb[i]?.termsUseAgree === "Y" ? "N" : "Y";
            }
            nb[i] = { ...nb[i], [key]: newVal };
          } else {
            nb[i].preregFieldList[j] = {
              ...nb[i].preregFieldList[j],
              ["value"]: value,
            };
          }
        }
      });
      return nb;
    });
  };

  const loginTabChange = (name, e) => {
    if (e) e.preventDefault();
    setTab(name);
    setInfo({});
    setFindMember({});
  };

  const loadEventList = () => {
    if (!siteInfo.idx) {
      alert("올바른 접근이 아닙니다.");
      return;
    }
    const req = {
      path: "/v1/client/site/event/list",
      data: {
        siteIdx: siteInfo.idx,
        eventProgressStatus: "TODAY",
        eventStatus: "Y",
      },
    };
    
    _API.post(req).then((res) => {
      const { data } = res.data;
      if(data) {
        if (data.length == 1) {
          for (let i in data) {
            if(data[i].upcomingYn == "Y" || data[i].onAirYn == "Y") {
              data[i].activeYn = true
            } else {
              data[i].activeYn = false
            }
          }
        } else {
          for(let i in data) {
            data[i].activeYn = false
          }
        }
        setEventList(data);
      }
    });
  };

  

  const clickedIdCheck = () => {
    const idCheck = _CK.get("idCheck");
    if (idCheck === "Y") {
      _CK.set("idCheck", "N");
      setIdCheck("N");
    } else {
      _CK.set("idCheck", "Y");
      setIdCheck("Y");
    }
  };

  const onLoginClick = (e) => {
    if(e) e.preventDefault();
    if (tab === "회원"){
      if(document.getElementById("member_id").value == null || document.getElementById("member_id").value == ""){
        swal("이메일을 입력해 주세요.");
        return;
      }else{
        info["email"] = document.getElementById("member_id").value;
      }

      if(document.getElementById("member_password").value == null || document.getElementById("member_password").value == ""){
        swal("비밀번호를 입력해 주세요.");
        return;
      }else{
        info["password"] = document.getElementById("member_password").value;
      }
    }
    if (tab === "패스코드"){
      if(document.getElementById("psc_password").value == null || document.getElementById("psc_password").value == ""){
        swal("패스코드를 입력해 주세요.");
        return;
      }else{
        info["passCode"] = document.getElementById("psc_password").value;
      }      
    }
    console.log(info)
  
    if (tab === "패스코드"){
      if(passCodeOpt.length > 0){

        let array = passCodeOpt[0];
        
        const param = {
          path: "/v1/client/agreeStatus",
          data: {
            siteIdx: siteInfo.idx,
            passCode: info.passCode
          },
        };

        _API.post(param).then((res) => {
          if(res.data.status == 0){
            if((passCodeUseOpen == false &&  array.termsUseFormUse == "Y") && (passCodePrivacyOpen == false && array.termsPrivacyFormUse == "Y") && (passCodeOverseaOpen == false && array.termsOverseaUse == "Y")){
              setPassCodeUseOpen(true)
              setPassCodePrivacyOpen(true)
              setPassCodeOverseaOpen(true)
              return;
            }
            if((passCodeUseOpen == false &&  array.termsUseFormUse == "Y") && (passCodePrivacyOpen == false && array.termsPrivacyFormUse == "Y")){
              setPassCodeUseOpen(true)
              setPassCodePrivacyOpen(true)
              return;
            }
            if((passCodeUseOpen == false &&  array.termsUseFormUse == "Y") && (passCodeOverseaOpen == false && array.termsOverseaUse == "Y")){
              setPassCodeUseOpen(true)
              setPassCodeOverseaOpen(true)
              return;
            }
            if((passCodePrivacyOpen == false && array.termsPrivacyFormUse == "Y") && (passCodeOverseaOpen == false && array.termsOverseaUse == "Y")){
              setPassCodePrivacyOpen(true)
              setPassCodeOverseaOpen(true)
              return;
            }

            if(passCodePrivacyOpen == false && array.termsPrivacyFormUse == "Y"){
              setPassCodePrivacyOpen(true)
              return;
            }
            if(passCodeUseOpen == false &&  array.termsUseFormUse == "Y"){
              setPassCodeUseOpen(true)
              return;
            }
            if(passCodeOverseaOpen == false &&  array.termsOverseaUse == "Y"){
              setPassCodeOverseaOpen(true)
              return;
            }

            if(array.termsPrivacyFormEsUse == "Y" && passCodePrivacyAgree != true){
              const val = array.termsPrivacyFormLabel;
              alert(`${val}에 동의해 주세요.`);
              return;
            }
            if(array.termsOverseaEsUse == "Y" && passCodeOverseaAgree != true){
              const val = array.termsOverseaLabel;
              alert(`${val}에 동의해 주세요.`);
              return;
            }
            if(array.termsUseFormEsUse == "Y" && passCodeUseAgree != true){
              const val = array.termsUseFormLabel;
              alert(`${val}에 동의해 주세요.`);
              return;
            }
            setFirstLoginStatus(res.data.status)
            goLogin() 
          }else if(res.data.status == -1){
            showAlert("사이트 초기 옵션을 설정해주세요.");
            return;
          }else{
            goLogin() 
          }
        })
      }else{
        goLogin() 
      }
    }else{
      goLogin() 
    }
  };

  const goLogin = () => {
    const req = {
      path: "/v1/client/login",
      data: {
        email: info.email,
        password: info.password,
        passCode: info.passCode,
        siteIdx: siteInfo.idx,
        eventIdx: '',
        tab : tab === "패스코드" ? "PASSCODE" : (tab === "회원" ? "MEMBER" : "GUEST"),
        userAgent: _U.getUserAgent(),
      },
    };
    _API.post(req).then((res) => {
      const { data } = res;
      if (res.status !== "SUCCESS") {
        swal("접근 권한이 없습니다.");
        return;
      }
      if(tab == '회원' && eventList?.length <= 0){
        alert('예정중인 이벤트가 없습니다.');
        return;
      }
      if (tab === "회원" && _CK.get("idCheck") === "Y") {
        _CK.set("email", info.email);
      }
      if (tab === "패스코드" && data.accessKey) {
        _CK.setConsoleCountView(0);
        setIam(data.data);
        setAccessKey(data.accessKey);
        setPreregEventList(data.data.preregEventList);
        setPasscodeNext(true);
      } else {
        _CK.setAuth(data.accessKey);
        _CK.setLogKey(data?.data?.logKey);
        _CK.setConsoleCountView(0);
        saveIam(data.data);
        saveAuth(data.accessKey);
      }
    })
    .catch((e) => {
      // swal("접근 권한이 없습니다.");
    });
  }
  const goEventSessionList = (event, e) => {
    if (e) e.preventDefault();

    if (event?.sessionPubCnt <= 0) {
      alert("예정중인 세션 정보가 없습니다.");
      return;
    }

    let newIam = iam;
    newIam.eventIdx = event.idx;
    setIam(newIam);
    if(firstLoginStatus == 0){
      const param = {
        path: "/v1/client/termsAgree",
        data: {
          siteIdx: siteInfo.idx,
          passCode : info.passCode,
          passCodeUseAgree : passCodeUseAgree,
          passCodePrivacyAgree : passCodePrivacyAgree,
          passCodeOverseaAgree : passCodeOverseaAgree,
          preregIdx : newIam.preregIdx
        },
      };
      _API.post(param).then((res) => {
        console.log(res)
        const req = {
          path: "/v1/client/refresh/accessKey", 
          data:{iam:newIam}
        };
        _API.post(req).then((res) => {
          const { accessKey } = res.data;
          if (accessKey) {
            _CK.setAuth(accessKey);

            setTimeout(()=>{
              window.location.href = `/#/event/${event.idx}/register?passCode=${info?.passCode}`;
              window.location.reload();
            },300)
          }
        });
      })
    }else{
      const req = {
        path: "/v1/client/refresh/accessKey", 
        data:{iam:newIam}
      };
      _API.post(req).then((res) => {
        const { accessKey } = res.data;
        if (accessKey) {
          _CK.setAuth(accessKey);

          setTimeout(()=>{
            window.location.href = `/#/event/${event.idx}/register?passCode=${info?.passCode}`;
            window.location.reload();
          },300)
        }
      });
    }
  };
  
  const goMypage = e => {
    e?.preventDefault();
    let newIam = iam;
    newIam.eventIdx = event.idx;
    setIam(newIam);
    const req = {
      path: "/v1/client/refresh/accessKey",
      data:{iam:newIam}
    };
    _API.post(req).then((res) => {
      const { accessKey } = res.data;
      if (accessKey) {
        _CK.setAuth(accessKey);

        setTimeout(()=>{
          window.location.href = '/#/invitation';
          window.location.reload();
      },300)
      }
    });
  };

  const passCodeShowTerms = (type, e) => {
    if (e) e.preventDefault();
   
    $("body").css("overflow", "hidden");
    
    if (type === "termsUseAgree") {
      setTermsInfo({
         title: passCodeOpt[0]?.termsUseFormLabel,
         content: passCodeOpt[0]?.termsUseForm,
       });
     } else if (type === "termsPrivateAgree") {
      setTermsInfo({
         title: passCodeOpt[0]?.termsPrivacyFormLabel,
         content: passCodeOpt[0]?.termsPrivacyForm,
       });
     }else if (type === "termsOverseaAgree") {
      setTermsInfo({
         title: passCodeOpt[0]?.termsOverseaLabel,
         content: passCodeOpt[0]?.termsOversea,
       });
     }
     else {
      setTermsInfo(null);
     }

  }

  const gstShowTerms = (type, e) => {
    if (e) e.preventDefault();

    $("body").css("overflow", "hidden");

    if (type === "termsUseAgree") {
      setTermsInfo({
        title: siteInfo?.gstTermsUseLabel,
        content: siteInfo?.gstTermsUseForm,
       });
     } else if (type === "termsPrivateAgree") {
       setTermsInfo({
        title: siteInfo?.gstTermsPrivacyLabel,
        content: siteInfo?.gstTermsPrivacyForm,
       });
     }else if (type === "termsOverseaAgree") {
      setTermsInfo({
        title: siteInfo?.gstTermsOverseaLabel,
        content: siteInfo?.gstTermsOverseaForm,
      });
    }
     else if (type === "termsMarketAgree") {
       setTermsInfo({
        title: siteInfo?.gstTermsMarketLabel,
        content: siteInfo?.gstTermsMarketForm,
       });
     }
     else {
       setTermsInfo(null);
     }

  }

  const showTerms = (type, e) => {
    if (e) e.preventDefault();
    $("body").css("overflow", "hidden");

    if (type === "termsUseAgree") {
     setTermsInfo({
        title: "사이트이용약관",
        content: siteInfo?.memTermsUseForm,
      });
    } else if (type === "termsPrivateAgree") {
      setTermsInfo({
        title: "개인정보 수집 이용 동의서",
        content: siteInfo?.memTermsPrivacyForm,
      });
    }
    else if (type === "termsMarket") {
      setTermsInfo({
        title: "마케팅정보 수신동의",
        content: siteInfo?.memTermsMarketForm,
      });
    }
     
    else {
      setTermsInfo(null);
    }
  };

  const linkRel = () => { 
    window.open("https://nedrug.mfds.go.kr/pbp/CCBBB01/getItemDetail?itemSeq=201601746")
  }

  useEffect(() => {
    $("#openModal").click(function (e) {
      e.preventDefault();
      $("body").css("overflow", "hidden");
      setOpenModal(true);
    })
  }, []);
  const BrowserClose = () => {
    $(".modal_container").hide();
  }
  const modalClose = () => {
    setTermsInfo(null);
    setOpenModal(false);
    $("body").css("overflow", "auto");
  };
 
  useEffect(() => {
    eventList, documentRef.current.addEventListener("scroll", handleScroll);
  });
 
  return (
    <Fragment>
      <div style={{ height: "100%" }}>
        <section id="login_container">
          <div
            className="bg_img"
            style={{
              background: `${siteInfo?.siteMainImgUrl ? `url(${siteInfo?.siteMainImgUrl})` : (`url("https://cdn.onoffevent.com/bg/Site%20bg.png")` )}`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          >
            <div className="bg_img_hd">
              {siteInfo?.nameLoginPageShow == 'Y' ? (
                  <h1 style={{ color: siteInfo.nameColor }}>{siteInfo?.title}</h1>
              )
                : ""
              }
            </div>
          </div>
          <div className="login_inform">
            {(siteInfo?.logoUseYn == "Y" && siteInfo?.logoImgUrl) &&
              <div className="brd_logo" style={{ textAlign: "center" }}>
                { siteInfo?.logoImgUrl == null || siteInfo?.logoImgUrl == "" ?
                  "" :
                  <img src={siteInfo.logoImgUrl} alt={siteInfo?.name} />
                }
              </div>
            }
            <div className="login_inform_wrap">
              <ul className="login_inform_list">
                {loginTabArray.length > 1 ? (
                  <Fragment>
                    {loginTabArray?.map((item, i) => (
                      <li
                        key={i}
                        className={cx({ active: tab === item.name })}
                        onClick={loginTabChange.bind(this, item.name)}
                        style={{
                          width:
                            "calc( 100% / " + loginTabArray.length + " )",
                        }}
                      >
                        <span className={cx({ on: tab === item.name })}>
                          {item.name}
                        </span>
                      </li>
                    ))}
                  </Fragment>
                ) : (
                  <Fragment>
                    {loginTabArray?.map((item, i) => (
                      <div className="login_inform_list_div" key={i}>
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </Fragment>
                )}
              </ul>
              <form onSubmit={(e) => {e.preventDefault();}}>
                {tab === "회원" && (
                  <LoginMemberView
                    info={info}
                    idCheck={idCheck}
                    onLoginClick={onLoginClick}
                    clickedIdCheck={clickedIdCheck}
                    onSetInfo={onSetInfo}
                  />
                )}
                {tab === "패스코드" && (
                  <LoginPasscodeView
                    info={info}
                    passCodeOpt={passCodeOpt}
                    passcodeNext={passcodeNext}
                    isPasswordVisible={isPasswordVisible}
                    passCodePrivacyOpen={passCodePrivacyOpen}
                    passCodeUseOpen={passCodeUseOpen}
                    passCodeOverseaOpen={passCodeOverseaOpen}
                    onLoginClick={onLoginClick}
                    onSetInfo={onSetInfo}
                    preregEventList={preregEventList}
                    setIsPasswordVisible={setIsPasswordVisible}
                    goEventSessionList={goEventSessionList}
                    goMypage={goMypage}
                    passCodeShowTerms={passCodeShowTerms}
                    passCodePrivacyAgree={passCodePrivacyAgree}
                    passCodeUseAgree={passCodeUseAgree}
                    passCodeOverseaAgree={passCodeOverseaAgree}
                    setPassCodePrivacyAgree={setPassCodePrivacyAgree}
                    setPassCodeUseAgree={setPassCodeUseAgree}
                    setPassCodeOverseaAgree={setPassCodeOverseaAgree}
                  />
                )}
                {tab === "게스트" && (
                  <LoginGuestView
                    siteInfo={siteInfo}
                    eventList={eventList}
                    onSetEventListActive={onSetEventListActive}
                    gstShowTerms={gstShowTerms}
                    onSetGuestInfoList={onSetGuestInfoList}
                  />
                )}
              </form>

              {siteInfo?.isCampaignUse ? (
                <div className="login_list_cont">
                  <h3>진행중인 캠페인</h3>
                  {campaignList?.map((item, index) => {
                    return (
                      <>
                        <ul className="login_list">
                          <li>
                            <div className="login_list_thumb">
                              <img  src={item?.campaignBgUrl ? item.campaignBgUrl : liveImg} alt=""/>
                            </div>
                            <div className="login_list_desc">
                              <div>
                                {item?.progressTarget == 'ON' && (
                                  <span className="servicetype online">ONLINE</span>
                                )}
                                {item?.progressTarget == 'OFF' && (
                                  <span className="servicetype offline">OFFLINE</span>
                                )}
                                {item?.progressTarget == 'ONOFF' && (
                                  <span className="servicetype onoffline">ONOFFLINE</span>
                                )}
                                <a href={`https://${item.domain}/#/campaign/${item.idx}`} target="_blank">
                                    <h4>{item.name}</h4>
                                </a>
                              </div>
                              <p> {item.startDate &&
                                    moment(item.startDate).format(
                                      "YYYY.MM.DD(ddd) HH:mm"
                                    )}{" "}
                                  {item.endDate && ` ~ `}
                                  {/* {item.endDate && <br></br>} */}
                                  {item.endDate &&
                                  (moment(item.startDate).format("YYYY.MM.DD(ddd)") ===
                                    moment(item.endDate).format("YYYY.MM.DD(ddd)")
                                    ? moment(item.endDate).format("HH:mm")
                                    : moment(item.endDate).format("YYYY.MM.DD(ddd) HH:mm"))}
                              </p>
                            </div>
                          </li>
                        </ul>
                      </>
                    )
                  })}
                  {campaignList?.length < 1 && (
                    <div className="none_event">
                      <span>진행중인 캠페인이 없습니다.</span>
                    </div>
                  )}
                  {/* <a className="moreLink">캠페인 더보기</a> */}
                </div>
              ) : ''}
              <div className="cs_container">
                <h4>{siteInfo?.cs}</h4>
                <p dangerouslySetInnerHTML={{ __html: siteInfo?.csDescription }}/>
               {/*  <p>{siteInfo?.csDescription}</p>
                {siteInfo?.csRegCheck == "Y" ? <p>{siteInfo?.csRegTel}</p> : ""}
                {siteInfo?.csAccessCheck == "Y" ? (
                  <p>{siteInfo?.csAccessTel}</p>
                ) : (
                  ""
                )} */}
              </div>
              <div className="bg_img_ft">
                <pre
                  dangerouslySetInnerHTML={{
                    __html: siteInfo?.footer?.replace(/\n/g, ""),
                  }}
                />
                {tab == "회원" && siteInfo?.memberFooterCheck === "Y" ? (
                  <div className="ft_info2" style={{ marginTop: "30px" }}>
                    <a onClick={showTerms.bind(this, "termsPrivateAgree")}>개인정보처리방침.</a>
                    <a onClick={showTerms.bind(this, "termsUseAgree")}>이용약관</a>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </section>
        {termsInfo && (
          <Fragment>
            <div className="ft_modal_container">
              <div className="ft_modal_header">
                <div>
                  <h2>{termsInfo?.title}</h2>
                  <button
                    className="close_btn"
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      modalClose();
                    }}
                  >
                    <img src="https://cdn.onoffevent.com/common/icon_close.svg" />
                  </button>
                </div>
              </div>
              <div className="ft_modal_content">
                <div
                  dangerouslySetInnerHTML={{ __html: termsInfo?.content }}
                />
              </div>
            </div>
            <div className="modal_bg on"></div>
          </Fragment>
        )}
        {openModal && (
          <div className="modal_container">
            <button className="modal_close" onClick={modalClose}><img src="https://cdn.onoffevent.com/common/icon_close.svg" alt="닫기"/></button>
            <div className="modal_mid">
              <h4 style={{fontSize: "19px", wordBreak: "keep-all"}}>
                당신은 한국 GSK 웹 사이트에서
                다른 페이지로 이동하려고 하고 있습니다.<br/>
                이 링크를 클릭하면 다른 웹 사이트 또는 웹 채널로 이동하게 됩니다.
              </h4>
              <span>
                지금 이동하려는 페이지는 한국 GSK의 유관 사이트이거나
                한국 규정이 적용되지 않는 글로벌 GSK 사이트 또는 계열사일 수 있습니다.
                GSK가 관리하지 않거나 GSK가 소유하지 않은 제3자 사이트로 이동할 경우,
                GSK는 해당 사이트에서 제공하는 콘텐츠에 대해 책임지지 않습니다.
              </span>
            </div>
            <div className="btnBox">
              <button className="modalBtn cancelBtn" onClick={modalClose}>취소</button>
              <button className="modalBtn confirmBtn" onClick={linkRel}>계속</button>
            </div>
          </div>
        )}
        {_U.getUserAgent().browserName != "Chrome"
          && _U.getUserAgent().browserName != "Safari"
          && _U.getUserAgent().browserName != "Mobile Safari"
          && _U.getUserAgent().browserName != "Edge" ? (
            <div className="modal_container">
              <button className="modal_close" onClick={BrowserClose}><img src="https://cdn.onoffevent.com/common/icon_close.svg" alt="닫기" /></button>
              <div className="modal_mid">
                <h4>
                  현재 접속하신 브라우저에서는 서비스 이용에 어려움이 있을 수 있습니다.<br/>
                  원활한 서비스 이용을 위해 Chrome/Safari/Edge를 통한 접속을 부탁드립니다.
                </h4>
                <span>
                  To get the best possible experience using our website,
                  It is recommended that you use one of the browsers listed below.
                </span>
                <ul>
                  <li>
                    <a href="https://www.google.com/chrome/" target="_blank">
                      <img src={ChromeLogo} alt="chroem logo"/>
                      <span>Chrome</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://support.apple.com/ko_KR/downloads/safari" target="_blank">
                      <img src={SafariLogo} alt="safari logo"/>
                      <span>Safari</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.microsoft.com/ko-kr/edge/" target="_blank">
                      <img src={EdgeLogo} alt="edge logo"/>
                      <span>Edge</span>
                    </a>
                  </li>
                </ul>
                <span style={{textAlign:"center"}}>
                  해당 브라우저 아이콘을 클릭하면 다운로드 화면으로 이동합니다.<br/>
                  Please click icons to download.
                </span>
              </div>
              <div className="btnBox">
                <button className="modalBtn cancelBtn" onClick={BrowserClose}>닫기</button>
              </div>
            </div>
        ):null}
      </div>
      <DynamicStyles />
    </Fragment>
  );
};
